:root {
  --primary-color: #2c3e50;
  --secondary-color: #e74c3c;
  --text-color: #ecf0f1;
  --gradient-bg: linear-gradient(135deg, rgba(44, 62, 80, 0.9), rgba(231, 76, 60, 0.9));
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  padding-bottom: 1%;
}

body {
  font-family: 'Raleway', sans-serif;
  color: var(--text-color);
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
}

.background-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url('assets/snowPic.jpeg');
  background-size: cover;
  background-position: 50% 40%;
  opacity: 0.6;
  z-index: -2;
}

.gradient-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: var(--gradient-bg);
  z-index: -1;
}

.container {
  display: flex;
  min-height: 100vh;
}

nav {
  width: 60px;
  background-color: var(--primary-color);
  transition: width 0.3s ease;
  overflow: hidden;
  padding: 20px 0;
/* position: sticky;
top: 0;
height: 100vh; */
position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 1000;
}

nav:hover {
  width: 80px;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  padding: 10px 20px;
  display: flex;
  justify-content: center;
}

nav ul li a svg {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease, fill 0.3s ease;
  fill: var(--text-color);
}

nav ul li a:hover svg {
  transform: scale(1.2);
  fill: var(--secondary-color);
}

main {
  flex-grow: 1;
  padding: 40px;
  /* added below */
  margin-left: 60px; 
}

h1 {
  font-size: 3em;
  margin-bottom: 20px;
  color: var(--secondary-color);
  text-shadow: 4px 4px 10px black;
}

h2,h3,h4{
  text-shadow: 3px 3px 8px black;
}

p{
  text-shadow: 3px 3px 8px black;
   /* added below */
  line-height: 1.6;
}

.content {
  display: block;
  opacity: 1;
  transition: opacity 0.5s ease;
  /* added below */
  max-width: 100%;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.content {
  animation: fadeIn 0.3s ease-in;
}

.loading {
  display: none;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.loading::after {
  content: "";
  width: 50px;
  height: 50px;
  border: 5px solid var(--text-color);
  border-top: 5px solid var(--secondary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

#MTGCollection1{
display: grid;
column-gap: 1rem;
row-gap: 1rem;
justify-items: left;
}

.YTContainer {
  position: relative;
  border: 5px solid var(--secondary-color);
  transition: 1s ease;
  width: 360px;
  height: 203px;
  aspect-ratio: 16 / 9;
  overflow: hidden;
}
.YTContainer:hover {
  width: 535px;
  height: 301px;
}

.YTPlayer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}


@keyframes spin {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}

hr.hr-5 {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
  padding-bottom: 0.5%;
}


#portfolio {
  max-width: 800px;
  margin: 0 auto;
}

#portfolio h1,
#portfolio h2,
#portfolio h3,
#portfolio h4 {
  color: var(--secondary-color);
  margin-bottom: 10px;
}

#portfolio section {
  margin-bottom: 30px;
}

#portfolio .profile {
  text-align: center;
  margin-bottom: 40px;
}

#portfolio .profile h2 {
  font-size: 2em;
}

#portfolio .skills {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#portfolio .skill-category {
  flex-basis: 48%;
  margin-bottom: 20px;
}

#portfolio ul {
  list-style-type: none;
  padding-left: 0;
}

#portfolio li {
  padding-bottom: 1%;
  text-shadow: 3px 3px 8px black;
}

#portfolio .job,
#portfolio .degree {
  margin-bottom: 20px;
}

#portfolio .job h4,
#portfolio .degree h4 {
  margin-bottom: 5px;
}

#portfolio .job p,
#portfolio .degree p {
  font-style: italic;
  margin-bottom: 10px;
}

#portfolio a {
  color: var(--secondary-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

#portfolio a:hover {
  color: var(--text-color);
}

#about {
  max-width: 900px;
  margin: 0 auto;
}
#about h1, #about h2, #about h3,  #about h4{
  color: var(--secondary-color);
}

#about p {
  font-size: larger;
  margin: 2% 0;
  text-indent: 35px;
}

@media (max-width: 768px) {
  nav {
    width: 100%;
    height: auto;
    position: fixed;
    bottom: 0;
    top: auto;
    left: 0;
  }

  nav ul {
    display: flex;
    justify-content: space-around;
  }

  nav:hover {
    width: 100%;
  }

  main {
    margin-left: 0;
    padding: 20px;
    margin-bottom: 60px;
  }

  h1 {
    font-size: 2em;
  }

  #about p {
    margin: 2% 0;
    text-indent: 20px;
  }

  .YTContainer:hover {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.8em;
  }

  #about p {
    font-size: medium;
  }
}